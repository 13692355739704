<template>
  <div id="watermark">
    <div v-show="visible" class="info">
      <top-bar
        :title="
          !dataForm.id
            ? '新增生育登记信息'
            : edit
            ? '修改生育登记信息'
            : '查看生育登记信息'
        "
        :left="true"
      ></top-bar>
      <!-- 申请类型 -->
      <van-popup v-model="typeShow" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="list.typeList"
          value-key="label"
          @confirm="typeConfirm"
          @cancel="typeShow = false"
        />
      </van-popup>
      <!-- 申请方式 -->
      <van-popup v-model="applyTypeShow" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="list.applyTypeList"
          value-key="label"
          @confirm="applyTypeConfirm"
          @cancel="applyTypeShow = false"
        />
      </van-popup>
      <!-- 男方出生日期 -->
      <van-popup v-model="maleBirthdayShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.maleBirthday"
          type="date"
          title="请选择"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatDate"
          @cancel="maleBirthdayShow = false"
          @confirm="maleBirthdayConfirm"
        />
      </van-popup>
      <!-- 女方出生日期 -->
      <van-popup v-model="femaleBirthdayShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.femaleBirthday"
          type="date"
          title="请选择"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatDate"
          @cancel="femaleBirthdayShow = false"
          @confirm="femaleBirthdayConfirm"
        />
      </van-popup>
      <!-- 申请日期 -->
      <van-popup v-model="applyDateShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.applyDate"
          type="date"
          title="请选择"
          :formatter="formatDate"
          @cancel="applyDateShow = false"
          @confirm="applyDateConfirm"
        />
      </van-popup>
      <!-- 登记日期 -->
      <van-popup v-model="regDateShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.regDate"
          type="date"
          title="请选择"
          :formatter="formatDate"
          @cancel="regDateShow = false"
          @confirm="regDateConfirm"
        />
      </van-popup>
      <!-- 是否已孕 -->
      <van-popup v-model="pregnantShow" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="list.pregnantList"
          value-key="label"
          @confirm="pregnantConfirm"
          @cancel="pregnantShow = false"
        />
      </van-popup>
      <!-- 预产日期 -->
      <van-popup v-model="childBirthdayShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.childBirthday"
          type="date"
          title="请选择"
          :formatter="formatDate"
          @cancel="childBirthdayShow = false"
          @confirm="childBirthdayConfirm"
        />
      </van-popup>
      <!-- 末次经期 -->
      <van-popup v-model="impShow" position="bottom">
        <van-datetime-picker
          v-model="dataForm.imp"
          type="date"
          title="请选择"
          :formatter="formatDate"
          @cancel="impShow = false"
          @confirm="impConfirm"
        />
      </van-popup>

      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">{{
              !dataForm.id
                ? "新增生育登记信息"
                : edit
                ? "修改生育登记信息"
                : "查看生育登记信息"
            }}</span>
          </template>
        </van-cell>
        <van-cell
          title="申请类型"
          :value="show.typeTitle"
          @click="typeShow = edit"
          :clickable="edit"
          :value-class="{
            'value-common': show.typeTitl == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="申请方式"
          :value="show.applyTypeTitle"
          @click="applyTypeShow = edit"
          :clickable="edit"
          :value-class="{
            'value-common': show.applyTypeTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell title="证书编号">
          <van-field
            clearable
            clear-trigger="always"
            placeholder="请输入证书编号"
            v-model="dataForm.certNumber"
            :readonly="!edit"
          />
        </van-cell>
        <van-cell v-if="edit" title="男方身份证号">
          <van-field
            clearable
            clear-trigger="always"
            placeholder="(社区人员可填)"
            @blur="maleIdcard"
            v-model="dataForm.maleIdcard"
            :readonly="!edit"
          />
        </van-cell>
        <van-cell title="男方姓名">
          <van-field
            clearable
            clear-trigger="always"
            placeholder="请输入男方姓名"
            v-model="dataForm.maleName"
            :readonly="!edit"
          />
        </van-cell>
        <van-cell
          title="男方出生日期"
          :value="show.maleBirthdayTitle"
          @click="maleBirthdayShow = edit"
          :value-class="{
            'value-common': show.maleBirthdayTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell v-if="edit" title="女方身份证号">
          <van-field
            clearable
            clear-trigger="always"
            @blur="femaleIdcardInput"
            placeholder="(社区人员可填)"
            v-model="dataForm.femaleIdcard"
            :readonly="!edit"
          />
        </van-cell>
        <van-cell title="女方姓名">
          <van-field
            clearable
            clear-trigger="always"
            placeholder="请输入女方姓名"
            v-model="dataForm.femaleName"
            :readonly="!edit"
          />
        </van-cell>
        <van-cell
          title="女方出生日期"
          :value="show.femaleBirthdayTitle"
          @click="femaleBirthdayShow = edit"
          :value-class="{
            'value-common': show.femaleBirthdayTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="申请日期"
          :value="show.applyDateTitle"
          @click="applyDateShow = edit"
          :value-class="{
            'value-common': show.applyDateTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="登记日期"
          :value="show.regDateTitle"
          @click="regDateShow = edit"
          :value-class="{
            'value-common': show.regDateTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="是否已孕"
          :value="show.pregnantTitle"
          @click="pregnantShow = edit"
          :clickable="edit"
          :value-class="{
            'value-common': show.pregnantTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          v-if="dataForm.pregnant"
          title="预产日期"
          :value="show.childBirthdayTitle"
          @click="childBirthdayShow = edit"
          :value-class="{
            'value-common': show.childBirthdayTitle == '请选择',
          }"
          :is-link="edit"
        />
        <van-cell
          title="末次经期"
          :value="show.impTitle"
          @click="impShow = edit"
          :value-class="{
            'value-common': show.impTitle == '请选择',
          }"
          :is-link="edit"
        />
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button
            type="info"
            size="large"
            :loading="loading"
            round
            @click="submit"
            >完成</van-button
          >
        </van-col>
        <van-row gutter="10" v-else>
          <!-- <van-col :span="12">
            <van-button
              plain
              type="danger"
              size="large"
              round
              @click="deleteHandle"
              style="background-color: transparent"
              >删除</van-button
            >
          </van-col> -->
          <van-col :span="24">
            <van-button type="info" size="large" round @click="edit = true"
              >编辑</van-button
            >
          </van-col>
        </van-row>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { beforeFileUpload, formatterDateTimeWithOutSec } from "@/utils/utils";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      minDate: new Date(1980, 0, 1),
      maxDate: new Date(2030, 10, 1),
      id: "",
      visible: true,
      edit: false,
      loading: false,
      typeShow: false,
      applyTypeShow: false,
      maleBirthdayShow: false,
      femaleBirthdayShow: false,
      applyDateShow: false,
      regDateShow: false,
      pregnantShow: false,
      childBirthdayShow: false,
      impShow: false,
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
      show: {
        typeTitle: "请选择", //申请类型
        applyTypeTitle: "请选择", //申请方式
        pregnantTitle: "请选择", //是否已孕
        maleBirthdayTitle: "请选择", //男方出生日期
        femaleBirthdayTitle: "请选择", //女方出生日期
        applyDateTitle: "请选择", //申请日期
        regDateTitle: "请选择", //登记日期
        childBirthdayTitle: "请选择", //预产日期
        impTitle: "请选择", //末次经期
      },
      dataForm: {
        community: "",
        type: "", //申请类型
        applyType: "", //申请方式
        certNumber: "", //证书编号
        maleIdcard: "", //男方身份证号
        maleUserId: "0", //男方用户id
        maleName: "", //男方姓名
        maleBirthday: "", //男方出生日期
        femaleIdcard: "", //女方身份证号
        femaleUserId: "0", //女方用户id
        femaleName: "", //女方姓名
        femaleBirthday: "", //女方出生日期
        applyDate: "", //申请日期
        regDate: "", //登记日期
        pregnant: "", //是否已孕
        childBirthday: "", //预产日期
        imp: "", //末次经期,
        id: "",
      },
      list: {
        typeList: [
          { label: "一孩申请", value: "1" },
          { label: "二孩申请", value: "2" },
        ], //申请类型
        applyTypeList: [], //申请方式
        pregnantList: [
          { label: "未孕", value: "0" },
          { label: "已孕", value: "1" },
        ], //是否已孕
      },
    };
  },
  methods: {
    // 初始化
    init() {
      this.getDictTypeList("pregnancyType");
      if (this.dataForm.id) {
        // debugger;
        this.edit = false;
        this.getInfo();
      } else {
        this.edit = true;
      }
    },
    // 获取字典
    getDictTypeList(code) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/listDictByCode"),
        method: "post",
        params: this.$http.adornParams({
          code: code,
          orgId: this.$orgId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data.dicts);
          this.list.applyTypeList = data.dicts;
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    maleIdcard(e) {
      console.log(e);
      var numberId = { id: this.dataForm.maleIdcard, type: 1 };
      this.getUserInfoByIdcard(numberId);
    },
    femaleIdcardInput(e) {
      var numberId = { id: this.dataForm.femaleIdcard, type: 2 };
      this.getUserInfoByIdcard(numberId);
    },
    // 根据身份证号查询用户
    getUserInfoByIdcard(e) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/user/userByIdNumber"),
        method: "get",
        params: this.$http.adornParams({
          idNumber: e.id,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.userInfoList && data.userInfoList.length > 0) {
            //type 1:男生,2:女生
            if (e.type == 1) {
              (this.dataForm.maleName = data.userInfoList[0].name),
                (this.dataForm.maleUserId = data.userInfoList[0].id),
                (this.dataForm.maleBirthday = data.userInfoList[0].birthday),
                (this.show.maleBirthdayTitle = data.userInfoList[0].birthday);
            }
            if (e.type == 2) {
              (this.dataForm.femaleName = data.userInfoList[0].name),
                (this.dataForm.femaleUserId = data.userInfoList[0].id),
                (this.dataForm.femaleBirthday = data.userInfoList[0].birthday),
                (this.show.femaleBirthdayTitle = data.userInfoList[0].birthday);
            }
          } else {
            this.$toast.fail("身份号码输入错误或社区人员不存在");
            //type 1:男生,2:女生
            if (e.type == 1) {
              (this.dataForm.maleName = ""),
                (this.dataForm.maleUserId = 0),
                (this.dataForm.maleBirthday = ""),
                (this.show.maleBirthdayTitle = "请选择");
            }
            if (e.type == 2) {
              (this.dataForm.femaleName = ""),
                (this.dataForm.femaleUserId = 0),
                (this.dataForm.femaleBirthday = ""),
                (this.show.femaleBirthdayTitle = "请选择");
            }
          }
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/pregnancy/info/" + this.dataForm.id),
        method: "post",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data);
          (this.dataForm.type = data.appPregnancy.type), //申请类型
            (this.show.typeTitle = data.appPregnancy.typeStr),
            (this.dataForm.applyType = data.appPregnancy.applyType), //申请方式
            (this.show.applyTypeTitle = data.appPregnancy.applyTypeStr),
            (this.dataForm.certNumber = data.appPregnancy.certNumber), //证书编号
            (this.dataForm.maleName = data.appPregnancy.maleName), //男方
            (this.dataForm.maleUserId = data.appPregnancy.maleUserId),
            (this.dataForm.maleBirthday = data.appPregnancy.maleBirthday),
            (this.show.maleBirthdayTitle = data.appPregnancy.maleBirthday),
            (this.dataForm.femaleName = data.appPregnancy.femaleName), //女方
            (this.dataForm.femaleUserId = data.appPregnancy.femaleUserId),
            (this.dataForm.femaleBirthday = data.appPregnancy.femaleBirthday),
            (this.show.femaleBirthdayTitle = data.appPregnancy.femaleBirthday),
            (this.dataForm.applyDate = data.appPregnancy.applyDate), //申请日期
            (this.show.applyDateTitle = data.appPregnancy.applyDate),
            (this.dataForm.regDate = data.appPregnancy.regDate), //登记日期
            (this.show.regDateTitle = data.appPregnancy.regDate),
            (this.dataForm.pregnant = data.appPregnancy.pregnant), //是否已孕
            (this.show.pregnantTitle = data.appPregnancy.pregnantStr),
            (this.dataForm.childBirthday = data.appPregnancy.childBirthday), //预产日期
            (this.show.childBirthdayTitle = data.appPregnancy.childBirthday),
            (this.dataForm.imp = data.appPregnancy.imp), //末次经期
            (this.show.impTitle = data.appPregnancy.imp);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // deleteHandle() {
    //   this.$dialog
    //     .confirm({
    //       title: "确定删除吗？",
    //     })
    //     .then(() => {
    //       this.$http({
    //         url: this.$http.adornUrl("/wxapp/insured/delete"),
    //         method: "post",
    //         params: this.$http.adornParams({
    //           ids: [parseInt(this.dataForm.id)],
    //         }),
    //       }).then(({ data }) => {
    //         if (data && data.code === 0) {
    //           this.$toast.success({
    //             message: "删除成功",
    //             duration: 3000,
    //             onOpened: () => {
    //               setTimeout(() => {
    //                 this.$router.go(-1);
    //               }, 1500);
    //             },
    //           });
    //         } else {
    //           this.$toast.fail(data.msg);
    //         }
    //       });
    //     });
    // },
    // 提交
    submit() {
      if (this.dataForm.type === "") {
        return this.$toast.fail("失败:请选择申请类型");
      }
      if (this.dataForm.applyType === "") {
        return this.$toast.fail("失败:申请方式不能为空");
      }
      if (this.dataForm.certNumber === "") {
        return this.$toast.fail("失败:证书编号不能为空");
      }
      if (this.dataForm.maleName === "") {
        return this.$toast.fail("失败:男方姓名不能为空");
      }
      if (this.dataForm.maleBirthday === "") {
        return this.$toast.fail("失败:男方出生日期不能为空");
      }
      if (this.dataForm.femaleName === "") {
        return this.$toast.fail("失败:女方姓名不能为空");
      }
      if (this.dataForm.femaleBirthday === "") {
        return this.$toast.fail("失败:女方出生日期不能为空");
      }
      if (this.dataForm.applyDate === "") {
        return this.$toast.fail("失败:申请日期不能为空");
      }
      if (this.dataForm.regDate === "") {
        return this.$toast.fail("失败:登记日期不能为空");
      }
      if (this.dataForm.pregnant === "") {
        return this.$toast.fail("失败:是否已孕不能为空");
      } else {
        if (
          this.dataForm.pregnant === '1' &&
          this.dataForm.childBirthday === ""
        ) {
          return this.$toast.fail("失败:预产日期不能为空");
        }
      }

      this.loading = true;
      this.$http({
        url: this.$http.adornUrl(`/wxapp/pregnancy/save`),
        method: "post",
        data: 
          this.dataForm
        
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.dataForm.id ? "修改成功" : "新增成功"}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            },
          });
        } else {
          this.loading = false;
          this.$toast.fail(data.msg);
        }
      });
      this.loading = false;
    },
    // 申请类型选择
    typeConfirm(value) {
      if (value) {
        this.show.typeTitle = value.label;
        this.dataForm.type = value.value;
      }
      this.typeShow = false;
    },
    // 申请方式选择
    applyTypeConfirm(value) {
      if (value) {
        this.show.applyTypeTitle = value.label;
        this.dataForm.applyType = value.value;
      }
      this.applyTypeShow = false;
    },
    // 是否已孕选择
    pregnantConfirm(value) {
      if (value) {
        this.show.pregnantTitle = value.label;
        this.dataForm.pregnant = value.value;
      }
      this.pregnantShow = false;
    },

    // 日期选项格式化
    formatDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    // 男方出生日期
    maleBirthdayConfirm(value) {
      if (value) {
        this.show.maleBirthdayTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.maleBirthday = formatterDateTimeWithOutSec(value);
      }
      this.maleBirthdayShow = false;
    },
    // 女方出生日期
    femaleBirthdayConfirm(value) {
      if (value) {
        this.show.femaleBirthdayTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.femaleBirthday = formatterDateTimeWithOutSec(value);
      }
      this.femaleBirthdayShow = false;
    },
    // 申请日期
    applyDateConfirm(value) {
      if (value) {
        this.show.applyDateTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.applyDate = formatterDateTimeWithOutSec(value);
      }
      this.applyDateShow = false;
    },
    // 登记日期
    regDateConfirm(value) {
      if (value) {
        this.show.regDateTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.regDate = formatterDateTimeWithOutSec(value);
      }
      this.regDateShow = false;
    },
    // 预产日期
    childBirthdayConfirm(value) {
      if (value) {
        this.show.childBirthdayTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.childBirthday = formatterDateTimeWithOutSec(value);
      }
      this.childBirthdayShow = false;
    },
    // 末次经期
    impConfirm(value) {
      if (value) {
        this.show.impTitle = formatterDateTimeWithOutSec(value);
        this.dataForm.imp = formatterDateTimeWithOutSec(value);
      }
      this.impShow = false;
    },
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.id = this.$route.query.id || "";
    document.querySelector("html").style.backgroundColor = "#fff";
    this.dataForm.community=this.$orgId
    this.init();
  },
  beforeDestroy() {
    document.querySelector("html").style.backgroundColor = "";
  },
};
</script>